import { React, useState, useEffect } from "react";
import { getGPUTier } from 'detect-gpu';

import './App.css';

function App() {
  const [gpuTier, setGpuTier] = useState({});

  useEffect(() => {
    (async () => {
      const gpuTier = await getGPUTier();
      setGpuTier(() => gpuTier);
    
      // Example output:
      // {
      //   "tier": 1,
      //   "isMobile": false,
      //   "type": "BENCHMARK",
      //   "fps": 21,
      //   "gpu": "intel iris graphics 6100"
      // }
    })();
  },[]); // empty dependency list so function only runs once on mount of component
  

  return (
    <div className="App">
      <table className="App-header">
        <thead>
          <tr>
            <th align="center">Detect-gpu Property</th>
            <th align="center">Value</th>
          </tr>
        </thead>
        <tbody>
          {gpuTier == null ? 
            null : 
            Object.keys(gpuTier).map(key => {
              return(
                <tr key={key}>
                  <td align="center">{key}</td>
                  <td align="center">{String(gpuTier[key])}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default App;
